import _superagent from "superagent";
import superagentPromise from "superagent-promise";

import { errorHandler } from "./responseHandler";

const superagent = superagentPromise(_superagent, global.Promise);

export const API_ROOT = process.env.REACT_APP_SERVER_URL || "";

const responseBody = (res) => res.body;

// const encode = encodeURIComponent;

export let token = null;
const tokenPlugin = (req) => {
  if (token) {
    req.set("authorization", `Bearer ${token}`);
  }
};

const requests = {
  del: (url) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorHandler),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorHandler),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorHandler),
  post: (url, body?) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorHandler),
  formDataPost: (url, body?) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .set("Content-Type", "multipart/form-data")
      .then(responseBody)
      .catch(errorHandler),
};

const Auth = {
  login: (email, password) =>
    requests.post("/auth/sign-in?lang=en", { email, password }),
  profileSchools: () => requests.get("/profile/schools"),
};

const Grade = {
  getGrades: () => requests.get("/grade?lang=en"),
  createGrade: (gradeBody) => requests.post("/grade?lang=en", { ...gradeBody }),
  getGradesBySchoolId: (schoolId) =>
    requests.get(
      `/grade?filtered=${JSON.stringify([
        { id: "schoolId", value: schoolId },
      ])}&pageSize=-1`
    ),
};

const Section = {
  getSectionsByGradeId: (gradeId) =>
    requests.get(
      `/section?filtered=${JSON.stringify([
        { id: "gradeId", value: gradeId },
      ])}&lang=en&pageSize=-1`
    ),
  createGradeSection: (gradeSectionBody) =>
    requests.post("/section?lang=en", { ...gradeSectionBody }),
};

const School = {
  getSchools: () => requests.get("/school?lang=en"),
  createSchool: (schoolBody) => requests.post("/school", { ...schoolBody }),
};

const Dashboard = {
  getTeacherDashboardStats: (teacherId: string) =>
    requests.get(`/teacherDashboard/${teacherId}`),
  getUpcomingEvents: () => requests.get("/assessment/upcomingEvents?lang=en"),
};

const Course = {
  getCourses: () => requests.get("/course?lang=en"),
  getCoursesBySchoolId: (schoolId) =>
    requests.get(`/course/bySchoolId/${schoolId}?lang=en`),
  createCourse: (courseBody) =>
    requests.post("/course?lang=en", { ...courseBody }),
  getCategoriesInCourse: (courseId: string) =>
    requests.get(`/course/${courseId}/categories?lang=en`),
};

const Category = {
  getCategories: () => requests.get("/category?lang=en"),
  getCategoriesById: (id: string) => requests.get(`/category/${id}?lang=en`),
  getCategoriesByCourseId: (courseId) =>
    requests.get(
      `/category?filtered=${JSON.stringify([
        { id: "courseId", value: courseId },
      ])}`
    ),
  createCategory: (courseId, parentId, name) =>
    requests.post("/category?lang=en", { courseId, parentId, name }),
  updateCategory: (categoryId, courseId, parentId, name) =>
    requests.put(`/category/${categoryId}?lang=en`, {
      courseId,
      parentId,
      name,
    }),
  addCommentToCategory: (categoryId, comment) =>
    requests.put(`/category/${categoryId}/add-comment?lang=en`, {
      comment,
    }),
};

const Questions = {
  getQuestions: () => requests.get("/category?lang=en"),
  getQuestionByQuestionId: (questionId) =>
    requests.get(`/question/${questionId}?lang=en`),
  getQuestionsByCourseId: (courseId) =>
    requests.get(
      `/question?lang=en&filtered=${JSON.stringify([
        { id: "courseId", value: courseId },
      ])}&pageSize=-1`
    ),
  getQuestionAuthors: (courseCode) =>
    requests.get(`/course/authors/${courseCode}`),
  autoSelectQuestions: (autoSelectBody) =>
    requests.post("/question/autoSelect?lang=en", { ...autoSelectBody }),
  filterQuestion: (filterBody) =>
    requests.post("/question/filter?lang=en", { ...filterBody }),
  createQuestion: (questionBody) =>
    requests.post("/question?lang=en", { ...questionBody }),
  updateQuestion: (questionId, questionBody) =>
    requests.put(`/question/${questionId}?lang=en`, { ...questionBody }),
  deleteQuestion: (questionId) =>
    requests.del(`/question/soft-delete/${questionId}?lang=en`),
  // GPT Questions
  bulkGenerateQuestions: (bulkGenerateBody) =>
    requests.post("/question/bulkgenerate?lang=en", { ...bulkGenerateBody }),
  bulkRgenerateQuestions: (bulkRegenerateBody) =>
    requests.post("/question/regenerate?lang=en", { ...bulkRegenerateBody }),
  bulkCreateQuestions: (bulkCreateBody) =>
    requests.post("/question/bulkcreate?lang=en", [...bulkCreateBody]),
};

const Assessments = {
  getAssessments: () => requests.get("/assessment?lang=en"),
  getAssessmentsByGrade: (submissionId) =>
    requests.get(`/assessment/submission/${submissionId}?lang=en`),
  getAssessmentsByCourseId: (courseId) =>
    requests.get(
      `/assessment?lang=en&filtered=${JSON.stringify([
        { id: "courseId", value: courseId },
      ])}&pageSize=100`
    ),
  getAssessmentsByCourseCodeAndTeacherId: (courseCode, teacherId) =>
    requests.get(
      `/assessment/getByCourseCode/${courseCode}/${teacherId}?pageSize=100`
    ),
  getAssessmentById: (assessmentId) =>
    requests.get(`/assessment/${assessmentId}?lang=en`),
  createAssessment: (assessmentBody) =>
    requests.post("/assessment?lang=en", { ...assessmentBody }),
  deleteAssessment: (assessmentId) =>
    requests.del(`/assessment/soft-delete/${assessmentId}?lang=en`),
  updateAssessment: (assessmentId, assessmentBody) =>
    requests.put(`/assessment/${assessmentId}?lang=en`, { ...assessmentBody }),
  updateAssessmentWeightage: (assessmentId, assessmentBody) =>
    requests.post(`/assessment/${assessmentId}/weightage?lang=en`, {
      ...assessmentBody,
    }),
  publishAssessment: (assessmentBody) =>
    requests.post("/assessment/publishAssessment?lang=en", {
      ...assessmentBody,
    }),
  submitAssessment: (assessmentBody) =>
    requests.post("/assessment/submitAssessment?lang=en", {
      ...assessmentBody,
    }),
  gradeSubmission: (gradeSubmissionBody) =>
    requests.post("/assessment/gradeSubmission?lang=en", {
      ...gradeSubmissionBody,
    }),
  getAssessmentWeightage: (courseId) =>
    requests.get(`/assessment/${courseId}/weightage?lang=en`),
  archiveAssessment: (assessmentId) =>
    requests.post(`/assessment/${assessmentId}/archived?lang=en`),
};

const Teacher = {
  getTeachersBySchoolId: (schoolId) =>
    requests.get(`/teachers/school/${schoolId}?lang=en`),
  createTeacher: (teacherBody) =>
    requests.post("/auth/sign-up?lang=en", { ...teacherBody }),
  bulkCreateTeacher: (teacherBody) =>
    requests.post("/auth/bulk-sign-up?lang=en", teacherBody),
  getTeacherCourses: (teacherId) =>
    requests.get(`/teachers/${teacherId}/courses?lang=en`),
  addTeacherInCourse: (teacherBody) =>
    requests.post("/course/add-teacher?lang=en", { ...teacherBody }),
};

const Student = {
  getStudentsBySchoolId: (schoolId) =>
    requests.get(`/students/school/${schoolId}?lang=en`),
  createStudent: (studentBody) =>
    requests.post("/auth/sign-up?lang=en", { ...studentBody }),
  bulkCreateStudent: (studentBody) =>
    requests.post("/auth/bulk-sign-up?lang=en", studentBody),
  getStudentCourses: (studentId) =>
    requests.get(`/students/${studentId}/courses?lang=en`),
  addStudentInCourse: (studentBody) =>
    requests.post("/course/add-student?lang=en", { ...studentBody }),

  // Old
  getStudents: () => requests.get("/students/"),
  getStudentAssessments: (studentId) =>
    requests.get(`/students/${studentId}/assignments`),
  getStudentExams: (studentId) => requests.get(`/students/${studentId}/exams`),
  getStudentById: (studentId) => requests.get(`/students/${studentId}`),
  getStudentsByGradeId: (gradeId) => requests.get(`/students/grade/${gradeId}`),
  getStudentsSAWById: (studentId) => requests.get(`/sawS/${studentId}`),
  getStudentStatsByStudentId: (profileId: string) =>
    requests.get(`/studentDashboard/${profileId}`),
  getStudentStengthAndWeakness: (studentId, gradeId) =>
    requests.get(`/sawS/${studentId}/${gradeId}`),
};

const agent = {
  Auth,
  Grade,
  Course,
  School,
  Student,
  Section,
  Teacher,
  Category,
  Questions,
  Dashboard,
  Assessments,
  setToken: (_token) => (token = _token),
};

export default agent;
