import { lazy, Suspense } from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";

import { SomeUserLoginDetails } from "src/@types/User";

import { PUBLIC_ROUTES, PROTECTED_ROUTES } from "src/constants/Navigation";

import Loader from "src/components/Loader";

const RoutesWithLayout = lazy(() => import("src/components/RoutesWithLayout"));

// Public Routes
const Login = lazy(() => import("src/screens/Auth/Login"));
// Private Routes
const Dashboard = lazy(() => import("src/screens/Dashboard"));
const Grade = lazy(() => import("src/screens/Grade"));
const Course = lazy(() => import("src/screens/Course"));
const Category = lazy(() => import("src/screens/Category"));
const CategoryGradeCourseListing = lazy(
  () => import("src/screens/Category/CategoryGradeCourseListing")
);
const CategoriesManagement = lazy(
  () => import("src/screens/Category/CategoriesManagement")
);
const TeachersListing = lazy(() => import("./screens/Teachers"));
const TeacherDetails = lazy(() => import("./screens/Teachers/TeacherDetails"));
const StudentsListing = lazy(() => import("./screens/Students"));
const StudentDetails = lazy(() => import("./screens/Students/StudentDetails"));

const PrivateRoutes = () => {
  const userData: string = localStorage.getItem("@sart_admin");
  let userDataParsed: SomeUserLoginDetails;

  if (userData !== null) {
    userDataParsed = JSON.parse(userData) as SomeUserLoginDetails;
  }

  return userDataParsed?.token ? (
    <Outlet />
  ) : (
    <Navigate to={PUBLIC_ROUTES.LOGIN} />
  );
};

const MainRoutes: React.FC = () => {
  return (
    <Suspense fallback={<Loader fullscreen />}>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<RoutesWithLayout />}>
            <Route path={PROTECTED_ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route path={PROTECTED_ROUTES.CATEGORY} element={<Category />} />
            <Route
              path={PROTECTED_ROUTES.CATEGORY_GRADE_COURSES}
              element={<CategoryGradeCourseListing />}
            />
            <Route
              path={PROTECTED_ROUTES.CATEGORY_MANAGEMENT}
              element={<CategoriesManagement />}
            />
            <Route path={PROTECTED_ROUTES.GRADE} element={<Grade />} />
            <Route path={PROTECTED_ROUTES.COURSE} element={<Course />} />
            <Route
              path={PROTECTED_ROUTES.TEACHER}
              element={<TeachersListing />}
            />
            <Route
              path={PROTECTED_ROUTES.TEACHER_DETAILS}
              element={<TeacherDetails />}
            />
            <Route
              path={PROTECTED_ROUTES.STUDENT}
              element={<StudentsListing />}
            />
            <Route
              path={PROTECTED_ROUTES.STUDENT_DETAILS}
              element={<StudentDetails />}
            />
            <Route
              path={PROTECTED_ROUTES.ALL}
              element={<Navigate to={PROTECTED_ROUTES.DASHBOARD} />}
            />
          </Route>
          <Route
            path={PROTECTED_ROUTES.ALL}
            element={<Navigate to={PROTECTED_ROUTES.DASHBOARD} />}
          />
        </Route>
        <Route path={PUBLIC_ROUTES.LOGIN} element={<Login />} />
        <Route path={PROTECTED_ROUTES.PRIVACY_POLICY} element={<div />} />
        <Route path={PROTECTED_ROUTES.TERMS} element={<div />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
