import * as React from "react";
import toast from "react-hot-toast";

import { Generic } from "src/@types/Category";

import agent from "src/helpers/apiAgent";

import useAuth from "src/hooks/useAuth";
import useSchool from "src/hooks/useSchool";

const GRADE = {
  name: "",
  schoolId: "",
};

// Set defaults for reuse
const DEFAULTS = {
  loaders: {
    grades: false,
    createGrade: false,
    createGradeSection: false,
  },
  grades: [],
  gradeData: { ...GRADE },
  resetGradeData: () => {},
  addGrade: (callback) => {},
  setRefetch: (val: Generic) => {},
  setGradeData: (val: Generic) => {},
  addGradeSection: (data: Generic, callback: Generic) => {},
};
const GradeContext = React.createContext(DEFAULTS);

const GradeProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  const { isLoggedIn } = useAuth();
  const { activeSchool } = useSchool();

  const [refetch, setRefetch] = React.useState(false);
  const [grades, setGrades] = React.useState(DEFAULTS.grades);
  const [loaders, setLoaders] = React.useState(DEFAULTS.loaders);
  const [gradeData, setGradeData] = React.useState(DEFAULTS.gradeData);

  // Setting default School ID
  React.useEffect(() => {
    if (activeSchool?.id) {
      setGradeData((prev) => ({ ...prev, schoolId: activeSchool?.id }));
    }
  }, [activeSchool]);

  React.useEffect(() => {
    const getGradesBySchoolId = async () => {
      try {
        setLoaders((prev) => ({ ...prev, grades: true }));
        const response = await agent.Grade.getGradesBySchoolId(
          activeSchool?.id
        );

        if (response.code === 200) {
          setGrades(response?.data);
        }
      } catch (error) {
      } finally {
        setLoaders((prev) => ({ ...prev, grades: false }));
      }
    };
    if (isLoggedIn && activeSchool?.id) {
      getGradesBySchoolId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, refetch, activeSchool]);

  const validateCreation = (data) => {
    if (!data?.name || data?.name < 3 || data?.name?.trim()?.length === 0) {
      toast.error("Name length should be greater than 3 characters!");
      return false;
    }
    if (!data?.schoolId) {
      toast.error("School not provided!");
      return false;
    }

    return true;
  };

  const addGrade = async (callback) => {
    try {
      if (validateCreation(gradeData)) {
        setLoaders((prev) => ({ ...prev, createGrade: true }));
        const response = await agent.Grade.createGrade(gradeData);

        if (response.code === 201) {
          setRefetch(!refetch);
          toast.success("Grade created successfully!");

          if (callback) {
            setGradeData({ ...GRADE });
            callback();
          }
        }
      }
    } catch (error) {
    } finally {
      setLoaders((prev) => ({ ...prev, createGrade: false }));
    }
  };

  const resetGradeData = () =>
    setGradeData({ ...GRADE, schoolId: activeSchool?.id });

  const validateSectionCreation = (data) => {
    if (!data?.name || data?.name?.trim()?.length === 0) {
      toast.error("Section name not provided!");
      return false;
    }
    if (!data?.gradeId) {
      toast.error("Grade not provided!");
      return false;
    }

    const grade = grades?.find((grade) => grade?.id === data?.gradeId);
    if (
      grade &&
      Object.keys(grade).length &&
      grade?.sections?.some(
        (sec) => sec?.name?.toLowerCase() === data?.name?.toLowerCase()
      )
    ) {
      toast.error("Section with this name already exists!");
      return false;
    }

    return true;
  };

  const addGradeSection = async (sectionData, callback) => {
    try {
      if (validateSectionCreation(sectionData)) {
        setLoaders((prev) => ({ ...prev, createGradeSection: true }));
        const response = await agent.Section.createGradeSection(sectionData);

        if (response.code === 201) {
          setRefetch(!refetch);
          toast.success("Grade section created successfully!");

          if (callback) {
            callback();
          }
        }
      }
    } catch (error) {
    } finally {
      setLoaders((prev) => ({ ...prev, createGradeSection: false }));
    }
  };

  const contextValues = {
    grades,
    loaders,
    addGrade,
    gradeData,
    setRefetch,
    setGradeData,
    resetGradeData,
    addGradeSection,
  };

  return (
    <GradeContext.Provider value={contextValues}>
      {children}
    </GradeContext.Provider>
  );
};

export { GradeContext };
export default GradeProvider;
