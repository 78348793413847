import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import MainRoutes from "./Router";
import agent from "src/helpers/apiAgent";
import { SomeUserLoginDetails } from "src/@types/User";

import AuthProvider from "src/providers/Auth";
import SchoolProvider from "./providers/School";
import DashboardProvider from "./providers/Dashboard";
import GradeProvider from "src/providers/Grade";
import TeacherProvider from "src/providers/Teacher";
import StudentProvider from "src/providers/Student";
import CourseProvider from "src/providers/Course";
import NewLectureProvider from "src/providers/NewLecture";
import NewQuestionProvider from "src/providers/NewQuestion";
import NewAssignmentProvider from "src/providers/NewAssignment";

const theme = createTheme({
  typography: {
    fontFamily: ["Avenir-Regular"].join(","),
  },
});

// *** Setting up agent token *** //
let userData: string | SomeUserLoginDetails =
  localStorage.getItem("@sart_admin");
if (userData !== null) {
  userData = JSON.parse(userData) as SomeUserLoginDetails;
  agent.setToken(userData?.token);
}
// *** Setting up agent token *** //

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <SchoolProvider>
            <DashboardProvider>
              <GradeProvider>
                <CourseProvider>
                  <TeacherProvider>
                    <StudentProvider>
                      <NewAssignmentProvider>
                        <NewLectureProvider>
                          <NewQuestionProvider>
                            <MainRoutes />
                          </NewQuestionProvider>
                        </NewLectureProvider>
                      </NewAssignmentProvider>
                    </StudentProvider>
                  </TeacherProvider>
                </CourseProvider>
              </GradeProvider>
            </DashboardProvider>
          </SchoolProvider>
        </AuthProvider>
        <Toaster position="top-center" reverseOrder={false} />
      </ThemeProvider>
    </Router>
  );
};

export default App;
