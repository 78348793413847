import * as React from "react";

import { Generic } from "src/@types/Category";

import agent from "src/helpers/apiAgent";
import { successHandler, errorHandler } from "src/helpers/responseHandler";

// import { PROTECTED_ROUTES } from "src/constants/Navigation";

// import useRoutes from "src/hooks/useRoutes";
import useNewAssignment from "src/hooks/useNewAssignment";

// Set defaults for reuse
const DEFAULTS = {
  loading: false,
  questionData: {
    questionType: 1,
    courseId: "",
    questionText: "",
    score: 0,
    difficulty: 1,
    options: [],
    tags: [],
  },
  updateId: "",
  subject: { id: "", name: "" },
  onCancel: () => {},
  resetQuestionData: () => {},
  onSubmitQuestion: async () => {},
  setSubject: (subject: Generic) => {},
  addQuestionTags: (value: string) => {},
  setQuestionData: (data: Generic) => {},
  removeQuestionTag: (value: string) => {},
  editQuestion: (data: Generic, subject: Generic) => {},
};

const QuestionContext = React.createContext(DEFAULTS);

const QuestionProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  // const { navigateTo } = useRoutes();
  const { setRefetch } = useNewAssignment();

  const [updateId, setUpdateId] = React.useState(DEFAULTS?.updateId);
  const [loading, setLoading] = React.useState(DEFAULTS.loading);
  const [subject, setSubject] = React.useState(DEFAULTS?.subject);
  const [questionData, setQuestionData] = React.useState(DEFAULTS.questionData);

  const resetQuestionData = () => {
    setQuestionData(DEFAULTS?.questionData);
    setUpdateId(DEFAULTS?.updateId);
  };

  const editQuestion = (data, subject) => {
    const extractedData = {};
    Object.keys(DEFAULTS?.questionData).forEach(
      (key) => (extractedData[key] = data[key] ?? DEFAULTS.questionData[key])
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setQuestionData(extractedData);
    setSubject(subject);
    setUpdateId(data?.id);
    // navigateTo(PROTECTED_ROUTES.ADD_QUESTIONS, { state: { subject } });
  };

  const addQuestionTags = (val: string) => {
    const index = questionData?.tags.indexOf(val, 0);
    if (index > -1) {
      const updated = [...questionData?.tags];
      updated.splice(index, 1);
      setQuestionData((prev) => ({ ...prev, tags: updated }));
    } else {
      setQuestionData((prev) => ({
        ...prev,
        tags: [...questionData?.tags, val],
      }));
    }
  };

  const removeQuestionTag = (val: string) => {
    const index = questionData?.tags.indexOf(val, 0);
    if (index > -1) {
      const updated = [...questionData?.tags];
      updated.splice(index, 1);
      setQuestionData((prev) => ({ ...prev, tags: updated }));
    }
  };

  const isQuestionDataValid = () => {
    if (!questionData?.courseId) {
      errorHandler("Please select a course!");
      return false;
    }
    if (!questionData?.questionType) {
      errorHandler("Please select question type!");
      return false;
    }
    if (!questionData?.questionText) {
      errorHandler("Please enter question title!");
      return false;
    }
    if (
      questionData?.questionType === 1 && // if question type is MCQ
      questionData?.options?.length < 2 // then must have atleast 2 options
    ) {
      errorHandler("For a MCQ type question please provide atleast 2 options!");
      return false;
    }
    if (
      questionData?.questionType === 1 && // if question type is MCQ
      !questionData?.options?.some((op) => op?.isCorrectAnswer) // and 1 of them must be right answer
    ) {
      errorHandler(
        "For a MCQ type question, Correct answer not Entered/Missing!"
      );
      return false;
    }
    if (questionData?.tags?.length === 0) {
      errorHandler("Please provide the AI Tags for the question!");
      return false;
    }
    if (!questionData?.difficulty) {
      errorHandler("Please provide the difficulty level of the question!");
      return false;
    }
    return true;
    // return Boolean(
    //   questionData?.courseId &&
    //     (questionData?.questionType === 2 || // Question type check
    //       (questionData?.questionType === 1 && // if question type is MCQ
    //         questionData?.options?.length >= 2 && // then must have atleast 2 options
    //         questionData?.options?.some((op) => op?.isCorrectAnswer))) && // and 1 of them must be right answer
    //     questionData?.questionText &&
    //     questionData?.difficulty
    // );
  };

  const onSubmitQuestion = async () => {
    // Making decision to update or create question
    if (updateId) {
      await onUpdateQuestion();
    } else if (!updateId) {
      await onCreateQuestion();
    }
    setRefetch((prev) => !prev);
  };

  const onUpdateQuestion = async () => {
    try {
      // checking data validity
      if (isQuestionDataValid()) {
        setLoading(true);
        const response = await agent.Questions.updateQuestion(
          updateId,
          questionData
        );
        if (response.code === 200) {
          successHandler(response);
          onCancel();
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onCreateQuestion = async () => {
    try {
      // checking data validity
      if (isQuestionDataValid()) {
        setLoading(true);
        const response = await agent.Questions.createQuestion(questionData);
        if (response.code === 201) {
          successHandler(response);
          onCancel();
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    resetQuestionData();
    // navigateTo(PROTECTED_ROUTES.QUESTIONS_LISTING, { state: { subject } });
  };

  const contextValues = {
    loading,
    subject,
    onCancel,
    updateId,
    setSubject,
    questionData,
    editQuestion,
    addQuestionTags,
    setQuestionData,
    onSubmitQuestion,
    removeQuestionTag,
    resetQuestionData,
  };

  return (
    <QuestionContext.Provider value={contextValues}>
      {children}
    </QuestionContext.Provider>
  );
};

export { QuestionContext };
export default QuestionProvider;
