export const ROLES = {
  SUPER_ADMIN: "8d8449b9-c665-4dba-88d1-8b53f06f8f35",
  TEACHER: "da7ac4a4-2964-406a-8337-1dff63caea93",
  STUDENT: "4bae9535-df47-46fe-b395-7be379d01f31",
};

export const DEFAULT_COLS = {
  flex: 1,
  minWidth: 150,
  floatingFilter: true,
};
