export const PUBLIC_ROUTES = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  SET_PASSWORD: "/set-password",
  ALL: "*",
};

export const PROTECTED_ROUTES = {
  ALL: "/",
  DASHBOARD: "/dashboard",
  CATEGORY: "/category",
  CATEGORY_GRADE_COURSES: "/category-domain-wise-subjects",
  CATEGORY_MANAGEMENT: "/category/category-management",
  GRADE: "/grade",
  ADD_GRADE: "/add-grade",
  COURSE: "/course",
  ADD_COURSE: "/add-course",
  TEACHER: "/teacher",
  TEACHER_DETAILS: "/teacher-details",
  STUDENT: "/student",
  STUDENT_DETAILS: "/student-details",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS: "/terms",
};

export const SideBarRoutes = [
  {
    label: "Dashboard",
    route: PROTECTED_ROUTES.DASHBOARD,
    icon: "icon-dashboard",
  },
  {
    label: "Category",
    route: PROTECTED_ROUTES.CATEGORY,
    icon: "icon-category",
  },
  { label: "Grade", route: PROTECTED_ROUTES.GRADE, icon: "icon-grade" },
  { label: "Course", route: PROTECTED_ROUTES.COURSE, icon: "icon-course" },
  {
    label: "Teacher",
    route: PROTECTED_ROUTES.TEACHER,
    icon: "icon-students",
  },
  { label: "Student", route: PROTECTED_ROUTES.STUDENT, icon: "icon-students" },
];
