import * as React from "react";
// import toast from "react-hot-toast";

import { Generic } from "src/@types/Category";

import { CATEGORY } from "src/components/Dropzone";

// import { PROTECTED_ROUTES } from "src/constants/Navigation";

// import useCourse from "src/hooks/useCourse";
// import useRoutes from "src/hooks/useRoutes";

// import agent, { token, API_ROOT } from "src/helpers/apiAgent";

// import { errorHandler } from "src/helpers/responseHandler";

// Set defaults for reuse
const DEFAULTS = {
  loaders: {
    lecture: false,
    file: false,
  },
  lectureData: {
    name: "",
    description: "",
    tagSlots: [],
    type: CATEGORY.Video,
    transcription: "",
    link: "",
  },
  updateId: "",
  fileName: "",
  removeFile: () => {},
  resetLectureData: () => {},
  addLectureTags: (val: string) => {},
  handleFileUpload: (e: Generic) => {},
  setLectureData: (data: Generic) => {},
  removeLectureTags: (val: string) => {},
  onSubmitLecture: async (courseId: string) => false,
  editLecture: (data: Generic, subject: Generic) => {},
};

const LectureContext = React.createContext(DEFAULTS);

const LectureProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  // const { setRefetch } = useCourse();
  // const { navigateTo } = useRoutes();

  const [loaders] = React.useState(DEFAULTS.loaders);
  const [fileName, setFileName] = React.useState(DEFAULTS.fileName);
  const [updateId, setUpdateId] = React.useState(DEFAULTS?.updateId);
  const [lectureData, setLectureData] = React.useState(DEFAULTS.lectureData);

  // const lectureValidate = () => {
  //   if (!lectureData?.name) {
  //     errorHandler("Please enter lecture title!");
  //     return false;
  //   }
  //   if (!lectureData?.description) {
  //     errorHandler("Please enter lecture description!");
  //     return false;
  //   }
  //   if (!lectureData?.type) {
  //     errorHandler("Please provide type of lecture!");
  //     return false;
  //   }
  //   if (!lectureData?.link) {
  //     errorHandler("Please provide lecture material!");
  //     return false;
  //   }
  //   if (lectureData?.tagSlots?.length === 0) {
  //     errorHandler("Please provide the AI Tags for the lecture!");
  //     return false;
  //   }
  //   return true;
  // };

  const resetLectureData = () => {
    setLectureData(DEFAULTS?.lectureData);
    setUpdateId(DEFAULTS?.updateId);
    setFileName(DEFAULTS?.fileName);
  };

  const addLectureTags = (val: string) => {
    const index = lectureData?.tagSlots?.findIndex((t) => t?.tagId === val);
    if (index > -1) {
      const updated = [...lectureData?.tagSlots];
      updated.splice(index, 1);
      setLectureData((prev) => ({ ...prev, tagSlots: updated }));
    } else {
      setLectureData((prev) => ({
        ...prev,
        tagSlots: [
          ...lectureData?.tagSlots,
          { startTime: "00:00", endTime: "00:00", tagId: val },
        ],
      }));
    }
  };

  const removeLectureTags = (val: string) => {
    const index = lectureData?.tagSlots?.findIndex((t) => t?.tagId === val);
    if (index > -1) {
      const updated = [...lectureData?.tagSlots];
      updated.splice(index, 1);
      setLectureData((prev) => ({ ...prev, tagSlots: updated }));
    }
  };

  const editLecture = (data, subject) => {
    const extractedData = { ...DEFAULTS.lectureData };
    Object.keys(DEFAULTS?.lectureData).forEach(
      (key) => (extractedData[key] = data[key] ?? DEFAULTS.lectureData[key])
    );
    setLectureData(extractedData);
    setUpdateId(data?.id);
    if (extractedData?.link) {
      const fileName = extractedData?.link?.split("/");
      setFileName(fileName[fileName?.length - 1]?.replaceAll("_", " "));
    }
    // navigateTo(PROTECTED_ROUTES.COURSE_TOPIC_ADD_LECTURE, {
    //   state: { subject },
    // });
  };

  const onSubmitLecture = async (courseId: string) => {
    // Making decision to update or create question
    //   let response = false;
    //   if (updateId) {
    //     response = await handleUpdateLecture();
    //   } else if (!updateId) {
    //     response = await handleSubmitLecture(courseId);
    //   }
    //   setRefetch((prev) => !prev);
    //   return response;
    // };
    // const handleUpdateLecture = async () => {
    //   try {
    //     // checking data validity
    //     if (lectureValidate()) {
    //       setLoaders((prev) => ({ ...prev, lecture: true }));
    //       const response = await agent.Course.updateLecture(
    //         updateId,
    //         lectureData
    //       );
    //       if (response) {
    //         toast.success("Lecture updated!");
    //         setLectureData(DEFAULTS?.lectureData);
    //         setRefetch((prev) => !prev);
    //         return true;
    //       }
    //       return false;
    //     }
    //   } catch (error) {
    //     return false;
    //   } finally {
    //     setLoaders((prev) => ({ ...prev, lecture: true }));
    //   }
    return false;
  };

  // const handleSubmitLecture = async (courseId: string) => {
  // try {
  //   // validate data
  //   if (lectureValidate()) {
  //     setLoaders((prev) => ({ ...prev, lecture: true }));
  //     const response = await agent.Course.createLecture(lectureData);
  //     if (response) {
  //       toast.success("Lecture created!");
  //       const courseResponse = await agent.Course.addLectureInCourse(
  //         response?.data?.id,
  //         courseId
  //       );
  //       if (courseResponse) {
  //         setFileName(DEFAULTS.fileName);
  //         setLectureData(DEFAULTS?.lectureData);
  //       }
  //       setRefetch((prev) => !prev);
  //       return true;
  //     }
  //     return false;
  //   } else {
  //     return false;
  //   }
  // } catch (error) {
  //   return false;
  // } finally {
  //   setLoaders((prev) => ({ ...prev, lecture: false }));
  // }
  //   return false;
  // };

  const handleFileUpload = async (file) => {
    // try {
    //   if (file?.length) {
    //     const formData = new FormData();
    //     formData.append("fileUpload", file[0]);
    //     setLoaders((prev) => ({ ...prev, file: true }));
    //     let response = await fetch(
    //       `${API_ROOT}${agent.Course.uploadLectureFile}`,
    //       {
    //         method: "post",
    //         body: formData,
    //         headers: {
    //           authorization: `Bearer ${token}`,
    //         },
    //       }
    //     );
    //     if ([200, 201].includes(response?.status)) {
    //       response = await response.json();
    //       setLectureData((prev) => ({
    //         ...prev,
    //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //         // @ts-ignore
    //         link: response?.data ?? "",
    //       }));
    //       setFileName(file[0].name);
    //     } else {
    //       errorHandler("Failed to upload file");
    //     }
    //   }
    // } catch (error) {
    //   errorHandler(error);
    //   return false;
    // } finally {
    //   setLoaders((prev) => ({ ...prev, file: false }));
    // }
  };

  const removeFile = () => {
    setLectureData((prev) => ({ ...prev, link: "" }));
    setFileName(DEFAULTS.fileName);
  };

  const contextValues = {
    loaders,
    fileName,
    updateId,
    removeFile,
    lectureData,
    editLecture,
    setLectureData,
    addLectureTags,
    onSubmitLecture,
    handleFileUpload,
    resetLectureData,
    removeLectureTags,
  };

  return (
    <LectureContext.Provider value={contextValues}>
      {children}
    </LectureContext.Provider>
  );
};

export { LectureContext };
export default LectureProvider;
