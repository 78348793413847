// Questions
export const QuestionTypeResolver = {
  1: "MCQ",
  2: "Descriptive",
  3: "Pictorial",
  4: "White Board",
};

export const QuestionDifficultyResolver = {
  1: "Easy",
  2: "Medium",
  3: "Hard",
  4: "Hardest",
  5: "Inferno",
};

export const DifficultyScale = {
  10: 1, // Easy
  30: 2, // Medium
  50: 3, // Hard
  70: 4, // Hardest
  90: 5, // Inferno
};

// Assignments
export const AssessmentStatus = {
  Drafted: 1,
  Published: 2,
  Submitted: 3,
  Checked: 4,
  Graded: 5,
  Pending: 6,
  InProgress: 7,
};

export const AssessmentStatusResolver = {
  1: "Drafted", // Assignment creation in progress, not yet published
  2: "Published", // Assignment is publically available
  3: "Submitted", // Student filled and submitted the assignment, but teacher yet to grade
  4: "Checked", // Student filled and submitted the assignment, teacher also checked the assignment. This is non-gradeable assignment
  5: "Graded", // Student filled and submitted the assignment, teacher also graded the assignment.
  6: "Pending", // Student hasn't filled and/or submitted the assignment yet
  7: "InProgress", // Allah jane
};

export const AssessmentTypes = {
  Assignment: 1,
  Exam: 2,
  Quiz: 3,
};

export const AssessmentTypesResolver = {
  1: "Assignment",
  2: "Exam",
  3: "Quiz",
};

export const AssessmentDifficulty = {
  Easy: 1,
  Medium: 2,
  Hard: 3,
  Hardest: 4,
  Inferno: 5,
};

export const SliderDifficultyLevels = [
  {
    value: 10,
    label: "Easy",
  },
  {
    value: 30,
    label: "Medium",
  },
  {
    value: 50,
    label: "Hard",
  },
  {
    value: 70,
    label: "Hardest",
  },
  {
    value: 90,
    label: "Inferno",
  },
];

export const LectureType = {
  1: "Video",
  2: "Audio",
  3: "Theory",
};
