import * as React from "react";
import toast from "react-hot-toast";

import { Generic } from "src/@types/Category";
import { ROLES } from "src/constants/common";

import agent from "src/helpers/apiAgent";

import useAuth from "src/hooks/useAuth";
import useSchool from "src/hooks/useSchool";

const STUDENT = {
  email: "",
  phone: "",
  fullName: "",
  schoolId: "",
  picturePath: "",
  RoleId: ROLES?.STUDENT,
};

// Set defaults for reuse
const DEFAULTS = {
  loaders: {
    students: false,
    assignCourse: false,
    createStudent: false,
    createBulkStudent: false,
    assignedCoursesList: false,
  },
  students: [],
  refetch: false,
  studentData: { ...STUDENT },
  resetStudentData: () => {},
  addStudent: (callback) => {},
  setRefetch: (val: Generic) => {},
  setStudentData: (data: Generic) => {},
  addBulkStudents: (data: Generic, callback) => {},
  getStudentCourses: async (studentId: string) => [],
  assignCourseToStudent: async (student, selectedCourseId, callback) => {},
};
const StudentContext = React.createContext(DEFAULTS);

const StudentProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  const { isLoggedIn } = useAuth();
  const { activeSchool } = useSchool();

  const [refetch, setRefetch] = React.useState(false);
  const [loaders, setLoaders] = React.useState(DEFAULTS.loaders);
  const [students, setStudents] = React.useState(DEFAULTS.students);
  const [studentData, setStudentData] = React.useState(DEFAULTS.studentData);

  React.useEffect(() => {
    if (activeSchool?.id) {
      setStudentData((prev) => ({ ...prev, schoolId: activeSchool?.id }));
    }
  }, [activeSchool]);

  React.useEffect(() => {
    const getStudentsBySchoolId = async () => {
      try {
        setLoaders((prev) => ({ ...prev, students: true }));
        const response = await agent.Student.getStudentsBySchoolId(
          activeSchool?.id
        );

        if (response.code === 200) {
          setStudents(response?.data);
        }
      } catch (error) {
      } finally {
        setLoaders((prev) => ({ ...prev, students: false }));
      }
    };
    if (isLoggedIn && activeSchool?.id) {
      getStudentsBySchoolId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, activeSchool, refetch]);

  const validateCreation = (data) => {
    if (!data?.schoolId) {
      toast.error("Student's school is not associated!");
      return false;
    }
    if (
      !data?.fullName ||
      data?.fullName < 3 ||
      data?.fullName?.trim()?.length === 0
    ) {
      toast.error("Student's name length should be greater than 3 characters!");
      return false;
    }
    if (!data?.email || data?.email?.trim()?.length === 0) {
      toast.error("Student's email not provided!");
      return false;
    }
    if (
      !data?.phone ||
      !(data?.phone?.length >= 10 && data?.phone?.length < 15) ||
      data?.phone?.trim()?.length === 0
    ) {
      toast.error(
        "Student's phone number length should between 10 to 15 digits!"
      );
      return false;
    }

    return true;
  };

  const addStudent = async (callback) => {
    try {
      if (validateCreation(studentData)) {
        setLoaders((prev) => ({ ...prev, createStudent: true }));
        const response = await agent.Student.createStudent(studentData);

        if (response.code === 201) {
          toast.success("Student created successfully!");
          setRefetch(!refetch);

          if (callback) {
            resetStudentData();
            callback();
          }
        }
      }
    } catch (error) {
    } finally {
      setLoaders((prev) => ({ ...prev, createStudent: false }));
    }
  };

  const resetStudentData = () =>
    // Preserving schoolId
    setStudentData({ ...STUDENT, schoolId: activeSchool?.id });

  const getStudentCourses = async (studentId) => {
    try {
      setLoaders((prev) => ({ ...prev, assignedCoursesList: false }));
      const response = await agent.Student.getStudentCourses(studentId);
      if (response.code === 200) {
        return response?.data?.studentCourses;
      }
      return [];
    } catch (error) {
      return [];
    } finally {
      setLoaders((prev) => ({ ...prev, assignedCoursesList: false }));
    }
  };

  const validateCourseAssignment = (selectedCourseId, studentId) => {
    if (!studentId) {
      toast.error("Please select a student to assign course!");
      return false;
    }
    if (!selectedCourseId) {
      toast.error("Please select a course to assign!");
      return false;
    }

    return true;
  };

  const assignCourseToStudent = async (student, selectedCourseId, callback) => {
    try {
      if (validateCourseAssignment(selectedCourseId, student?.studentId)) {
        setLoaders((prev) => ({ ...prev, assignCourse: false }));
        const response = await agent.Student.addStudentInCourse({
          StudentId: student?.studentId,
          CourseId: selectedCourseId,
        });

        if (response.code === 201) {
          toast.success("Course assigned successfully!");
          setRefetch(!refetch);

          if (callback) {
            callback();
          }
        }
      }
    } catch (error) {
    } finally {
      setLoaders((prev) => ({ ...prev, assignCourse: false }));
    }
  };

  const validateBulkCreation = (data) => {
    if (!Array.isArray(data)) {
      toast.error("Failed to obtain students data!");
      return false;
    }
    if (data?.length === 0) {
      toast.error("Failed to obtain students data!");
      return false;
    }
    if (data?.some((t) => !(t?.name || t?.email))) {
      toast.error("Some students's name or email is missing!");
      return false;
    }

    return true;
  };

  const addBulkStudents = async (bulkStudentData, callback) => {
    try {
      if (validateBulkCreation(bulkStudentData)) {
        bulkStudentData = bulkStudentData?.map((t) => ({
          ...t,
          RoleId: ROLES?.STUDENT,
          schoolId: activeSchool?.id,
        }));
        console.log({ bulkStudentData });
        setLoaders((prev) => ({ ...prev, createBulkStudent: true }));
        const response = await agent.Student.bulkCreateStudent(bulkStudentData);

        if (response.code === 201) {
          toast.success("Students created successfully!");
          setRefetch(!refetch);

          if (callback) {
            callback();
          }
        }
      }
    } catch (error) {
    } finally {
      setLoaders((prev) => ({ ...prev, createBulkStudent: false }));
    }
  };

  const contextValues = {
    students,
    loaders,
    refetch,
    addStudent,
    setRefetch,
    studentData,
    setStudentData,
    addBulkStudents,
    resetStudentData,
    getStudentCourses,
    assignCourseToStudent,
  };

  return (
    <StudentContext.Provider value={contextValues}>
      {children}
    </StudentContext.Provider>
  );
};

export { StudentContext };
export default StudentProvider;
