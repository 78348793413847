import * as React from "react";
import toast from "react-hot-toast";

import { Generic } from "src/@types/Category";

import agent from "src/helpers/apiAgent";

import useAuth from "src/hooks/useAuth";
import useSchool from "src/hooks/useSchool";

const COURSE = {
  name: "",
  icon: "icon-",
  gradeId: "",
  courseCode: "",
  section: "",
};

// Set defaults for reuse
const DEFAULTS = {
  activeCourseId: "", // To be removed in Future
  activeGrade: { id: "", name: "" }, // To be removed in Future
  loaders: {
    courses: false,
    categories: false,
    createCourse: false,
  },
  courseData: { ...COURSE },
  courses: [],
  categories: {},
  cleanedCourses: {},
  resetCourseData: () => {},
  addCourse: (callback) => {},
  setRefetch: (val: Generic) => {},
  setCourseData: (data: Generic) => {},
  setActiveCourseId: (id: string) => {}, // To be removed in Future
  getCategories: () => {}, // To be removed in Future
};
const CourseContext = React.createContext(DEFAULTS);

const CourseProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  const { isLoggedIn } = useAuth();
  const { activeSchool } = useSchool();

  const [refetch, setRefetch] = React.useState(false);
  const [loaders, setLoaders] = React.useState(DEFAULTS.loaders);
  const [courses, setCourses] = React.useState(DEFAULTS.courses);
  const [categories, setCategories] = React.useState(DEFAULTS.categories);
  const [courseData, setCourseData] = React.useState(DEFAULTS.courseData);
  const [cleanedCourses, setCleanedCourses] = React.useState(
    DEFAULTS.cleanedCourses
  );

  React.useEffect(() => {
    const getCoursesBySchoolId = async () => {
      try {
        setLoaders((prev) => ({ ...prev, courses: true }));
        const response = await agent.Course.getCoursesBySchoolId(
          activeSchool?.id
        );

        if (response.code === 200) {
          setCourses(response?.data);
        }
      } catch (error) {
      } finally {
        setLoaders((prev) => ({ ...prev, courses: false }));
      }
    };
    if (isLoggedIn && activeSchool?.id) {
      getCoursesBySchoolId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, activeSchool, refetch]);

  React.useEffect(() => {
    if (isLoggedIn && courses?.length) {
      getCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses]);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const promisesWithDelay = async (courses, delayInMilliseconds) => {
    const responses = [];
    // console.log({ courses });
    for (const course of courses) {
      if (course && course.id) {
        const response = await agent.Course.getCategoriesInCourse(course?.id);
        responses.push(response);
        await delay(delayInMilliseconds);
      }
    }
    // const cleanedCourses = [];
    // for (const course of courses) {
    //   if (
    //     course &&
    //     course?.id &&
    //     !cleanedCourses?.some((cc) => cc?.id === course?.id)
    //   ) {
    //     cleanedCourses.push(course);
    //   }
    // }

    // console.log({ cleanedCourses });
    // for (const course of cleanedCourses) {
    //   const response = await agent.Course.getCategoriesInCourse(course?.id);
    //   responses.push(response);
    //   await delay(delayInMilliseconds);
    // }
    return responses;
  };

  const getCategories = React.useCallback(async () => {
    try {
      setLoaders((prev) => ({ ...prev, categories: true }));

      // const promises = courses.map((course) =>
      //   agent.Course.getCategoriesInCourse(course?.id)
      // );
      // const responses = await Promise.all(promises);

      const delayInMilliseconds = 0.1; // 1 millisecond delay

      const responses = await promisesWithDelay(courses, delayInMilliseconds);

      const _categories = {};
      if (responses.some((res) => res?.code === 200)) {
        responses?.forEach((course, idx) => {
          // Replacing parent id null with 0
          course?.data?.categories?.forEach((cat) => {
            if (!cat.parent) {
              cat.parent = 0;
            }
          });

          // creating categories with course id
          _categories[courses[idx]?.id] = {
            categories: course?.data?.categories,
          };
        });
        setCategories(_categories);
      }
    } catch (error) {
    } finally {
      setLoaders((prev) => ({ ...prev, categories: false }));
    }
  }, [courses]);

  // create action for getCategoriesByCourseId and call only that when newly created and then slowly update the use of getCategories

  React.useEffect(() => {
    if (courses?.length) {
      const groupedByGrade = {};
      const _cleanedCourses = {};

      courses.forEach((item) => {
        const gradeName = item.grade.name;
        if (!groupedByGrade[gradeName]) {
          groupedByGrade[gradeName] = [];
        }
        if (
          !groupedByGrade[gradeName].some(
            (existingItem) => existingItem.courseCode === item.courseCode
          )
        ) {
          groupedByGrade[gradeName].push(item);
        }
      });

      for (const grade in groupedByGrade) {
        _cleanedCourses[grade] = groupedByGrade[grade];
      }

      setCleanedCourses(_cleanedCourses);
    }
  }, [courses]);

  const validateCreation = (data) => {
    if (!data?.name || data?.name < 3 || data?.name?.trim()?.length === 0) {
      toast.error("Name length should be greater than 3 characters!");
      return false;
    }
    if (!data?.gradeId) {
      toast.error("Course grade not provided!");
      return false;
    }
    if (!data?.courseCode || data?.courseCode?.trim()?.length === 0) {
      toast.error("Course code not provided!");
      return false;
    }
    if (!data?.section || data?.section?.trim()?.length === 0) {
      toast.error("Course section not provided!");
      return false;
    }

    return true;
  };

  const addCourse = async (callback) => {
    try {
      if (validateCreation(courseData)) {
        setLoaders((prev) => ({ ...prev, createCourse: true }));
        const response = await agent.Course.createCourse(courseData);

        if (response.code === 201) {
          toast.success("Course created successfully!");
          setRefetch(!refetch);

          if (callback) {
            setCourseData({ ...COURSE });
            callback();
          }
        }
      }
    } catch (error) {
    } finally {
      setLoaders((prev) => ({ ...prev, createCourse: false }));
    }
  };

  const resetCourseData = () => setCourseData({ ...COURSE });

  const contextValues = {
    activeCourseId: "", // // To be removed in Future
    activeGrade: { id: "", name: "" }, // To be removed in Future
    courses,
    loaders,
    addCourse,
    courseData,
    setRefetch,
    categories,
    setCourseData,
    getCategories,
    cleanedCourses,
    resetCourseData,
    setActiveCourseId: (id) => {}, // // To be removed in Future
  };

  return (
    <CourseContext.Provider value={contextValues}>
      {children}
    </CourseContext.Provider>
  );
};

export { CourseContext };
export default CourseProvider;
