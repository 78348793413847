import * as React from "react";

import {
  DEFAULT_USER,
  AuthContextType,
  SomeUserLoginDetails,
} from "src/@types/User";

import { PUBLIC_ROUTES } from "src/constants/Navigation";

import useRoutes from "src/hooks/useRoutes";

import agent from "src/helpers/apiAgent";

import { errorHandler } from "src/helpers/responseHandler";

const DefaultUserLoginDetails: SomeUserLoginDetails = DEFAULT_USER;

// Set defaults for reuse
const DEFAULTS = {
  isLoggedIn: false,
  user: DefaultUserLoginDetails,
  setUserLoggedOut: () => {},
  setLoggedUser: (data: SomeUserLoginDetails) => {},
};
const AuthContext = React.createContext<AuthContextType>(DEFAULTS);

const AuthProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  const { navigateTo } = useRoutes();

  const [user, setUser] = React.useState(DEFAULTS.user);
  const [logged, setLogged] = React.useState(DEFAULTS.isLoggedIn);

  const checkUserLogin = () => {
    try {
      let userData: string | SomeUserLoginDetails =
        localStorage.getItem("@sart_admin");
      if (userData !== null) {
        userData = JSON.parse(userData) as SomeUserLoginDetails;
        setLoggedUser(userData);
        agent.setToken(userData?.token);
      } else {
        setUserLoggedOut();
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  React.useEffect(() => {
    checkUserLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (typeof window != "undefined") {
      window.addEventListener("storage", checkUserLogin);
    }

    return () => window.removeEventListener("storage", checkUserLogin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLoggedUser: AuthContextType["setLoggedUser"] = (
    data: SomeUserLoginDetails
  ) => {
    setUser((prevData: object) => ({ ...prevData, ...data }));
    localStorage.setItem("@sart_admin", JSON.stringify(data));
    setLogged(true);
  };

  const setUserLoggedOut: AuthContextType["setUserLoggedOut"] = () => {
    setUser(DEFAULTS.user);
    setLogged(DEFAULTS.isLoggedIn);
    localStorage.clear();
    agent.setToken(null);
    navigateTo(PUBLIC_ROUTES.LOGIN);
  };

  const contextValues = {
    user,
    isLoggedIn: logged,
    setLoggedUser,
    setUserLoggedOut,
  };

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
export default AuthProvider;
