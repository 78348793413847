import * as React from "react";

import { Generic } from "src/@types/Category";

import Loader from "src/components/Loader";

import agent from "src/helpers/apiAgent";

import useAuth from "src/hooks/useAuth";

// Set defaults for reuse
const DEFAULTS = {
  loaders: {
    schools: false,
  },
  schools: [],
  setRefetch: (val: Generic) => {},
  activeSchool: { id: "", name: "" },
  setActiveSchool: (val: Generic) => {},
};
const SchoolContext = React.createContext(DEFAULTS);

const SchoolProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  const { isLoggedIn } = useAuth();

  const [refetch, setRefetch] = React.useState(false);
  const [loaders, setLoaders] = React.useState(DEFAULTS.loaders);
  const [schools, setSchools] = React.useState(DEFAULTS.schools);
  const [activeSchool, setActiveSchool] = React.useState(DEFAULTS.activeSchool);

  React.useEffect(() => {
    const getSchools = async () => {
      try {
        setLoaders((prev) => ({ ...prev, schools: true }));
        const response = await agent.School.getSchools();

        if (response.code === 200) {
          setSchools(response?.data);
          setActiveSchool(response?.data?.[0]);
        }
      } catch (error) {
      } finally {
        setLoaders((prev) => ({ ...prev, schools: false }));
      }
    };
    if (isLoggedIn) {
      getSchools();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, refetch]);

  const contextValues = {
    schools,
    loaders,
    setRefetch,
    activeSchool,
    setActiveSchool,
  };

  if (loaders?.schools) {
    return <Loader fullscreen />;
  }

  return (
    <SchoolContext.Provider value={contextValues}>
      {children}
    </SchoolContext.Provider>
  );
};

export { SchoolContext };
export default SchoolProvider;
